import React from "react";
import Section from "../../components/Section/Section";
import { PopUp } from 'components/Animations';

import "./ApproachCards.scss";

const ApproachCards = ({ cards }) => {
  return (
    <Section className="ApproachCards">
      <div className="container">
          <PopUp className="row">
            {cards.map((card, i) => {
              return (
                <div className="col-lg-4 z-100" key={i}>
                  <div className="ApproachCard">
                    <div className="ApproachCardTag">{card.tag}</div>
                    <h2 className="ApproachCardTitle">{card.title}</h2>
                    <div className="ApproachCardDescription Body--small">{card.description}</div>
                  </div>
                </div>
              );
            })}
          </PopUp>
      </div>
    </Section>
  );
};

export default ApproachCards;
